import React,{useState,useEffect} from 'react';
import FeedItem from './FeedItem';

function HomeFeed( {noimage, passedStyles, authorName, authorUrl} ) {
  const [data,setData]=useState([]);
  
  const getData=()=>{
    fetch('/json/content-list.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response){
        console.log(response)
        return response.json();
      })
      .then(function(myJson) {
        console.log(myJson.results);
        setData(myJson.results)
      });
  }
  useEffect(()=>{
    getData()
  },[])
  return (
    <div className="content-feed">
     {
       data && data.length > 0 && data.map((item, index)  =>
            index === 0 ?  <FeedItem key={"key"+index} item={item} /> : <FeedItem key={"key"+index} item={item} noimage />
       )
     }
    </div>
  );
}

export default HomeFeed;