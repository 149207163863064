import React, {useEffect, useRef} from 'react';

function SimpleSearch({ toggle, cb }) {
    
    const searchRef = useRef();
    
    const searchDropClickAway = (e) => {
        if(e.target.closest(".close-search")){
            e.preventDefault();
            // e.stopPropagation();
            cb(e);
            return; 
        }
        
        if( toggle && e.target && searchRef.current?.contains(e.target) === false){
            cb(e);
        }
    }

    useEffect( () => {
        document.addEventListener('mousedown', searchDropClickAway);
    })

    return (
        <>
        { toggle && 
            <div className="simple-search" ref={searchRef}>                
                <div className="header-search">
                    <button onClick={searchDropClickAway} className="close-search">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    <form>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                        </svg>
                        <button className="btn sm">Search</button>
                        <input placeholder="Search people, teams and publications..." />
                        
                    </form>
                </div>
                <div className="blur-cover"></div>
            </div>
        }
        </>
    );
}

export default SimpleSearch;
