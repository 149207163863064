import * as ReactDOM from 'react-dom';
import StandardButton from "./StandardButton";

function TrendingOrganizations() {
    const handleClick = (e) => {
        e.preventDefault();
        return false;
    };

    const orgs = [
        {
            "name": "IBM Research",
            "desc": "The hardworking team behind Klamr",
            "members": "200",
            "followers": "+1000",
            "avatar": "https://picsum.photos/100?1",
            "shortname": "ibmresearch"
        },
        {
            "name": "InnerSource@IBM",
            "desc": "Not quite opensource, but no completly closed source.",
            "members": "20",
            "followers": "+500",
            "avatar": "https://picsum.photos/100?2",
            "shortname": "innersource"
        },
        {
            "name": "Watson Core",
            "desc": "Remember Watson that won Jeapordy?  We mak it small and put it in your computer.",
            "members": "151",
            "followers": "+3000",
            "avatar": "https://picsum.photos/100?3",
            "shortname": "watsoncore"
        },
        {
            "name": "Opensource@IBM",
            "desc": "Redhat and all things opensource.",
            "members": "33",
            "followers": "+500",
            "avatar": "https://picsum.photos/100?4",
            "shortname": "opensource"
        }
    ];
    
    return (
      <div className="trending-organizations">
        <h3>Trending Teams</h3>
        <div className="orgs">
        {
            orgs.map((org)=>{
                return <a href="void(0)" onClick={handleClick} key={org.shortname}>
                    <span className="avatar"><img alt="" src={org.avatar} /></span>
                    <div className="org-info">
                        <h4>{org.name}</h4>
                        <p>{org.desc}</p>
                        <p className="metrics"><span>{org.members} members</span> | <span>{org.followers} followers</span></p>
                    </div>
                </a>
            })
        }
        </div>
        <StandardButton buttonLabel="See all teams" buttonModifiers="sm"/>
      </div>
    );
  }
  
  export default TrendingOrganizations;
  